
import { defineComponent, ref, computed, reactive, onMounted, watchEffect } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { TabsPaneContext } from 'element-plus'
import router from '@/router/clean';
import { etc, rule, notification } from '@/store/stateless';
import { healthTop, healthMiddle, healthLast, healthDefault } from '@/core/utils'

export default defineComponent({
    setup() {
        const ruleFormRef = ref<FormInstance>()

        const health = computed(() => {
            return store.state.customer.health;
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.customer.loading
        })

        const sendDeclarations = () => {
            // console.log(store.state.customer.leadInformation)
        }

        const isllrNa = computed(() => {

            if(health.value.insurance_type == 0 && health.value.is_llr == true)
                return true;

            return false;
        })

        const tablDisable = ref<boolean>(true)

        const activeName = ref('first')

        const reset = () => {
            Customers.setHealth(healthDefault)
        }

        const rules = reactive({
            name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
            phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
            dob: [{ validator: Validations.dob, trigger: ['blur'] }],
            gender: [{ validator: Validations.gender, trigger: ['blur'] }],
            is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change'] }],
            is_self: [{ validator: Validations.is_self, trigger: ['blur', 'change'] }],
            age_group: [{ validator: Validations.age_group, trigger: ['blur', 'change'] }],
            insured_type_id: [{ validator: Validations.insured_type_id, trigger: ['blur', 'change'] }],
            insured_city: [{ validator: Validations.insured_city, trigger: ['blur', 'change'] }],
            insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
            company_name: [{ validator: Validations.company_name, trigger: ['blur', 'change'] }],
            // expected_insurance_start_date: [{ validator: Validations.expected_insurance_start_date, trigger: ['blur', 'change'] }],
            nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change'] }],
            // agent: [{ validator: Validations.agent, trigger: ['blur', 'change'] }]
        })

        const revalidate = () => {
            if (isSubmitted.value) {
                ruleFormRef.value?.validate((valid) => {
                    if (!valid) {
                        // 
                    }
                })
            }
        }

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const familyRef = ref()
        const submitHealth = async (formEl: FormInstance | undefined) => {
            // console.log('click edit save')

            if([2,3,5].includes(health.value.insured_type_id) && health.value.members.length == 0){
                notification.warning(
                            'Customer',
                            'Plead add member'
                );
                return;
            }

            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    const result = await Customers.updateLead(health.value);
                    if (result.status == 200) {
                        notification.success(
                            'Customer',
                            'Customer has been updated successfully'
                        );
                        reset();
                        formEl.resetFields();
                        goLists(result.data.data.customer.id);
                    } else {
                        const { email_taken, phone_taken } = result.response.data.data
                        Validations.email_taken = email_taken
                        Validations.phone_taken = phone_taken
                        formEl.validate(async (valid) => {
                        })
                    }
                } else {
                    rule.customerDetails = Object.keys(fields)[0]
                    // console.log('rules',rule.customerDetails)
                    if (healthTop.includes(rule.customerDetails)) top.value.focus();
                    else if (healthMiddle.includes(rule.customerDetails)) middle.value.focus();
                    else if (healthLast.includes(rule.customerDetails)) last.value.focus();
                    else if(familyRef.value) familyRef.value.focus();
                }
            })
        }

        const submitDeclarations = async () => {
            const result = await Customers.declarations({
                lead_id: store.state.customer.leadId,
                lead_information: store.state.customer.leadInformation
            });
            if (result.status == 200) {
                notification.success(
                    'Health Declaration',
                    'Declaration successfully save'
                );
            } else {
                notification.warning(
                    'Health - Error',
                    result.response.data.message,
                );
            }
        }

        const addNewMember = () => {
            let relationship = <any>null
            if(health.value.insured_type_id == 7) relationship = "Referral"

            health.value?.members?.unshift({
                id: null,
                insured_type_id: null,
                insured_city: null,
                member_name: null,
                relationship,
                gender: null,
                dob: null,
                nationality_id: null,
                is_salary: null,
                declaration_answers: [],
                is_married: false
            })
        }

        const ql = ref(false)

        onMounted(async () => {
            reset()
            setCurrentPageBreadcrumbs("Edit User Details", [{ label: "Leads & Tasks", value: "CustomerLists" }]);
            await Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            })
            if(health.value.insured_type_id == 0)
                ql.value = true
        })

        const handleClick = (tab: TabsPaneContext, event: Event) => {
            // console.log(tab, event)
        }

        const goBack = () => {
            activeName.value = 'first';
        }

        const goLists = (customer_id: number) => {
            router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }

        watchEffect(() => {
            console.log(health.value);
            if (health.value != undefined && health.value != null) {
                // if (health.value.name && health.value.is_self && health.value.insured_type_id === 3) {
                //     const index = health.value.members[health.value.members.length - 1];
                //     index.member_name = health.value.name;
                //     index.gender = health.value.gender;
                //     index.dob = health.value.dob;
                //     index.nationality_id = health.value.nationality_id;
                // }
            }

        })

        const checkform = () => {
            const myForm: any = document.querySelectorAll('#healthTop')
            // console.log(myForm)
        }

        const clearValidate = (name) => {
            ruleFormRef.value?.clearValidate(name)
        }

        return {
            store,
            clickLoading,
            checkform,
            top,
            middle,
            last,
            revalidate,
            tablDisable,
            activeName,
            loading,
            rule,
            rules,
            health,
            ruleFormRef,
            etc,
            goLists,
            goBack,
            handleClick,
            submitHealth,
            sendDeclarations,
            submitDeclarations,
            addNewMember,
            ql,
            familyRef,
            isllrNa,
            clearValidate
        }
    },
})
